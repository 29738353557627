import styled, { keyframes } from 'styled-components';

const fadeLogin = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const showInput = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-35%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
`;

const nono = keyframes`
  0% {
     transform: translateX(0);
  }
  35% {
     transform: translateX(-15%);
  }
  70% {
     transform: translateX(15%);
  }
  100% {
     transform: translateX(0);
  }
    
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  font-weight: bold;
  padding: 30px 70px;
  width: 378px;
  animation: ${fadeLogin} 1s;
`;

export const Container = styled.div`

  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: #F2F2F2;
  flex-direction: column;

  .validade-error {
    animation: ${nono} 200ms linear, ${fadeLogin} paused;
    animation-iteration-count: 2;
  }

  form {
    display: flex;
    flex-direction: column;
    button {
      margin: 0 auto;
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-top: 20px;

      animation: ${showInput} 400ms;
      animation-delay: 150ms;
      animation-fill-mode: backwards;
    }

  
    .input-block:nth-child(1) {
      animation: ${showInput} 500ms;
      
    }
    .input-block:nth-child(2) {
      animation: ${showInput} 500ms;
      animation-delay: 100ms;
      animation-fill-mode: backwards;
    }

    
  }

  h2 {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
  }

  label {
    margin: 22px 0 0 22px;
    font-weight: normal;
    font-size: 13px;
  }

  span {
    width: 359px;
    text-align: center;
    font-size: 13px;
    color: #a4a4a4;
    margin-top: 40px;
  }
  a {
    padding: 20px;
    color: #4A4A4A;
    text-decoration: none;
    font-weight: normal;
  }
`;

export const MessageError = styled.div`
  margin: 20px 0 0 0;
  color: red;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
`;






