import styled, { keyframes } from 'styled-components';

const maxAsideWidth = 232;

const SlideAnimation = keyframes`
  from {
    transform: translateX(-${maxAsideWidth}px);
  }
  to{ 
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  background: #f4f4f2;
  a {
    text-decoration: none;
    color: #333;
    :hover {
      color: #666;
    }
  }
`;

export const Aside = styled.div`
  padding: 15px 0;
  display: flex;
  position: fixed;
  align-items: center;
  max-width: ${maxAsideWidth}px;
  min-width: 180px;
  width: 15%;
  height: 100%;
  background-color: #fff;
  flex-direction: column;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-height: 100%;
  z-index: 30;
  overflow-y: auto;
  @media screen and (max-width: 800px) {
    display: none;
  }
  ::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

export const Logo = styled.div`
  font-size: 30px;
  font-weight: 800;
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
  .hover-item {
    background: var(--primary-10);
  }
  li {
    text-align: center;
    padding: 0;
    justify-content: center;
    display: flex;
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 105px;
      font-size: 28px;
    }
    span {
      color: #333333;
      font-weight: bold;
      font-size: 20px;
      margin-top: 10px;
      font-family: 'ArialRoundedMt', sans-serif;
    }
    &:hover {
      background: var(--primary-10);
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - ${maxAsideWidth}px);
  margin-left: ${maxAsideWidth}px;
  padding: 0 30px;

  @media screen and (max-width: 800px) {
    width: 100%;
    margin-left: 0px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  align-items: center;
  border-bottom: 2px solid var(--primary);
  height: 70px;
  align-items: center;
  h3 {
    font-size: 24px;
    font-weight: bolder;
    font-family: 'ArialRoundedMt', sans-serif;
  }
  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
`;

export const ButtonLogout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
`;

export const Body = styled.div`
  margin-top: 20px;
  h1 {
    color: var(--primary);
    font-size: 21px;
    font-family: 'ArialRoundedMt', sans-serif;
    font-weight: bold;
  }
`;

export const SubMenu = styled.ul`
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  background-color: #fff;
  position: fixed;
  left: ${props => props.asideWidth + 'px'};
  width: 15%;
  max-width: ${maxAsideWidth}px;

  max-height: 100%;
  overflow-y: auto;
  z-index: 20;
  animation: ${SlideAnimation} 400ms;

  li {
    text-align: center;
    padding: 0;
    justify-content: center;
    display: flex;
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 120px;
      font-size: 28px;
    }
    span {
      color: #4a4a4a;
      font-weight: bold;
      font-size: 20px;
      margin-top: 10px;
      font-family: 'ArialRoundedMt', sans-serif;
    }
    &:hover {
      background: rgba(0, 67, 138, 0.1);
    }
    & + li {
      margin-top: 20px;
    }
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;
